import { ApiResponse } from "../models/ApiResponse/ApiResponse";
import { DraftReward, Reward, RewardClaim } from "../models/Reward/Reward";
import axiosClient from "./apiClient";

export async function getRewardsByPlace(
  placeId: string
): Promise<ApiResponse<Reward[]>> {
  try {
    const response = await axiosClient.get<ApiResponse<Reward[]>>(
      "Reward/GetRewardsByPlace",
      {
        params: {
          placeId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error("Error fetching rewards by place: " + error.message);
  }
}

export async function getDraftRewards(
  guid: string
): Promise<ApiResponse<DraftReward[]>> {
  try {
    const response = await axiosClient.get<ApiResponse<DraftReward[]>>(
      "Reward/GetDraftRewards?guid=" + guid
    );

    return response.data;
  } catch (error: any) {
    throw new Error("Error fetching draft rewards ", error.message);
  }
}

export async function claimReward(
  reward: RewardClaim
): Promise<ApiResponse<RewardClaim>> {
  try {
    const response = await axiosClient.post<ApiResponse<RewardClaim>>(
      "Reward/ClaimReward",
      reward
    );

    return response.data;
  } catch (error: any) {
    throw new Error("Error claiming reward! ", error.message);
  }
}
