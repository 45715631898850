import styles from "./Navbar.module.css";
// import logo from "../../assets/logo.png";
import logo from "../../assets/boba-b.png";

interface NavbarProps {
  scrollToContact: (() => void) | undefined;
}

const Navbar: React.FC<NavbarProps> = ({ scrollToContact }) => {

  return (
    <>
      <nav className={styles.navbar}>
        <img src={logo} className={styles.logo} alt="Logo" />
        <ul className={styles.navbarOptions}>
          {scrollToContact !== undefined && <div>
            <button
              className={`${styles.actionButton} ${styles.appleButton}`}
              onClick={scrollToContact}
            >
              <p>Kontakt</p>
            </button>
          </div>}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
