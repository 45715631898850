import styles from "./ContactForm.module.css";
import mailIcon from "../../assets/mail-icon-svg.svg";
import { useState } from "react";
import FormInput from "./FormInput/FormInput";
import emailjs from '@emailjs/browser';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Snackbar from "../Snackbar/Snackbar";

const serviceId = process.env.REACT_APP_SERVICE_ID
const templateId = process.env.REACT_APP_TEMPLATE_ID
const publicKey = process.env.REACT_APP_PUBLIC_KEY

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stateMessage, setStateMessage] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const initialFormData = {
    name: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true)
    setIsSuccess(null)

    emailjs.sendForm(
      serviceId!,
      templateId!,
      event.target,
      publicKey
    ).then((result) => {
      setStateMessage('Uspješno poslano!')
      setIsLoading(false)
      setIsSuccess(true)
      setTimeout(() => {
        setStateMessage('')
      }, 2500)
    }, (error) => {
      setStateMessage('Došlo je do greške, molim vas pokušajte kasnije!')
      setIsLoading(false)
      setIsSuccess(false)
      setTimeout(() => {
        setStateMessage('')
      }, 2500)
    })

    event.target.reset();
    setFormData(initialFormData);
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.formContainer}>
        <img
          src={mailIcon}
          alt="Kontaktirajte nas!"
          className={styles.mailIcon}
        />
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormInput
            label="Ime i Prezime"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <FormInput
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <FormInput
            label="Poruka"
            type="text"
            name="message"
            value={formData.message}
            onChange={handleChange}
            multiline={true}
          />
          <div>
            <button
              disabled={isLoading}
              type="submit"
              className={`${styles.actionButton} ${styles.appleButton} ${styles.contactButton}`}
            >
              <h2 className={styles.cardText}>Pošalji!</h2>
            </button>
          </div>
          {isLoading && <LoadingSpinner />}
          {stateMessage !== '' && isSuccess != null && <Snackbar success={isSuccess} text={stateMessage} />}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
