import styles from "./Scan.module.css";
import Navbar from "../../components/Navbar/Navbar";
import ProfileSection from "../../components/ProfileSection/ProfileSection";
import RewardData from "../../components/RewardData/RewardData";
import ApproveButton from "../../components/ApproveButton/ApproveButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { claimReward, getDraftRewards } from "../../lib/reward";
import { DraftReward, RewardClaim } from "../../models/Reward/Reward";
import { User } from "../../models/User/User";
import { getUserById } from "../../lib/user";

const Scan = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rewardData, setRewardData] = useState<DraftReward[]>([]);
  const [user, setUser] = useState<User>();
  const [rewardRedeemed, setRewardRedeemed] = useState<boolean>(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (id !== undefined) {
      fetchDraftRewards(id);
    }
  }, [id]);

  useEffect(() => {
    if (rewardData.length > 0) {
      fetchUserById(rewardData[0].uniqueId);
    }
  }, [rewardData]);

  const fetchUserById = async (uniqueId: string): Promise<void> => {
    try {
      const response = await getUserById(uniqueId);
      if (response.success) {
        setUser(response.data);
      }
    } catch (error: any) {
      console.error("Error fetching user by id ", error);
    }
  };

  const fetchDraftRewards = async (guid: string): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await getDraftRewards(guid);
      if (response.success) {
        setRewardData(response.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.error("Error fetching draft rewards ", error);
    }
  };

  const approveReward = async (): Promise<void> => {
    if (user !== undefined) {
      setIsLoading(true)
      const rewardClaim: RewardClaim = {
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        placeId: rewardData[0].placeId,
        placeName: rewardData[0].placeName,
        type: true,
        rewards: {},
        employeeName: 'Self-reward'
      };

      const newRewardMap = new Map<string, { item1: string; item2: number }>();

      rewardData.forEach((item: DraftReward) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newRewardMap.set(item.rewardId!.toString(), {
          item1: item.rewardName,
          item2: item.amount,
        });

        rewardClaim.type = item.rewardType as boolean;
      });

      const rewardsObject: Record<string, { item1: string; item2: number }> =
        {};
      newRewardMap.forEach((value, key) => {
        rewardsObject[key] = value;
      });

      rewardClaim.rewards = rewardsObject;

      try {
        const response = await claimReward(rewardClaim);
        if (response.success) {
          setRewardRedeemed(true);
          setTimeout(() => navigate('/'), 1500)
          console.log("Redeemed successfully!");
        }
        setIsLoading(false)
      } catch (error: any) {
        setIsLoading(false)
        console.error("Error claiming reward ", error);
      }
    }
  };

  return (
    <>
      <Navbar scrollToContact={undefined} />
      <div className={styles.container}>
        <div className={styles.detailsContainer}>
          {rewardData.length > 0 && user !== undefined && (
            <ProfileSection placeId={rewardData[0].placeId} user={user} />
          )}
          <RewardData rewardData={rewardData} />
        </div>
        <ApproveButton onPress={approveReward} />
        {rewardRedeemed && (
          <div className={styles.successContainer}>
            <p className={styles.heading16}>Nagrada odobrena!</p>
          </div>
        )}
      </div>

      {isLoading && (
        <div className={styles.loader}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default Scan;
