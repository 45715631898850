import { ApiResponse } from "../models/ApiResponse/ApiResponse";
import { User, UserBoba } from "../models/User/User";
import axiosClient from "./apiClient";

export async function getUserById(
  uniqueId: string
): Promise<ApiResponse<User>> {
  try {
    const response = await axiosClient.get<ApiResponse<User>>(
      "User/GetUserById?userId=" + uniqueId
    );

    return response.data;
  } catch (error: any) {
    throw Error("Error getting user by id " + error.message);
  }
}

export async function getSingleUserBobasByPlace(
  placeId: number,
  userId: number
): Promise<ApiResponse<UserBoba>> {
  try {
    const response = await axiosClient.get<ApiResponse<UserBoba>>(
      "Place/GetSingleUserBobasByPlace?placeId=" + placeId + "&userId=" + userId
    );

    return response.data;
  } catch (error: any) {
    throw Error("Error getting user bobas for place ", error);
  }
}
