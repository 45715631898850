import styles from "./Home.module.css";
import landing1 from "../assets/landing1.png";
import logo from "../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import FeatureCard from "../components/FeatureCard/FeatureCard";
import receiptImage from "../assets/receipt.svg";
import qrScanImage from "../assets/qr-scan.svg";
import { motion } from "framer-motion";
import ContactForm from "../components/ContactForm/ContactForm";
import HorizontalSwiper from "../components/HorizontalSwiper/HorizontalSwiper";
import MobileShowcase from "../components/MobileShowcase/MobileShowcase";
import { MutableRefObject } from "react";
import { useInView } from "react-intersection-observer";

interface HomeProps {
  sectionRef: MutableRefObject<HTMLElement | null>;
}

const Home: React.FC<HomeProps> = ({ sectionRef }) => {
  const { ref, inView } = useInView({ threshold: 0 });
  const scanHeading = "Osvojite Bobe skenirajući račune!";
  const scanText =
    "Korak po korak, osvojite bobe! Skenirajte svoj račun pomoću naše aplikacije kako biste automatski dodali bobe na svoj račun. Brzo i jednostavno!";

  const qrHeading = "Iskoristite svoje Bobe uz QR kod";
  const qrText =
    "Vaša nagrada čeka! Kada skupite dovoljno boba, pokažite svoj QR kod uposleniku lokala i iskoristite svoje nagrade. Jednostavno, praktično, uživajte u pogodnostima koje ste zaslužili!";

  const featureCards = [
    {
      heading: "Sve na jednom mjestu!",
      text:
        "Otkrijte i istražite omiljena mjesta lako uz Bobu! Naša platforma vam pruža brz pristup detaljnim informacijama i najnovijim ponudama. " +
        "Planiranje vašeg sljedećeg izlaska, večere ili druženja nikada nije bilo jednostavnije.",
    },
    {
      heading: "Interaktivno skupljanje boba",
      text:
        "Skupljajte bobe zabavno i interaktivno! Skenirajte račun nakon posjete i automatski zaradite bobe koje vas vode do fantastičnih nagrada. " +
        "Neka svaka posjeta bude korak bliže nagradama koje vas čekaju!",
    },
    {
      heading: "Nagrade koje se isplate",
      text:
        "Zaslužujete nagrade za lojalnost! Skupljene bobe pretvorite u ekskluzivne popuste i nagrade. Uživajte više dok štedite - pridružite se i " +
        "otkrijte niz pogodnosti koje vas čekaju sa svakim novim korakom.",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.firstTwoSections}>
        <section className={styles.blue}>
          <motion.div
            className={styles.headingWrapper}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h1 className={styles.heading}>
              Vaš novi omiljeni program lojalnosti!
            </h1>
            <div className={styles.logoWrapper}>
              <p className={styles.slogan}>POSJETI. OSVOJI. ISKORISTI</p>
              <motion.img
                src={logo}
                alt="Boba Logo"
                className={styles.logo}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              />
            </div>
          </motion.div>
          <motion.div
            className={styles.buttonWrapper}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <motion.button
              className={`${styles.actionButton} ${styles.appleButton}`}
              whileHover={{ scale: 1.05 }}
            >
              <FontAwesomeIcon
                icon={faApple}
                size="2x"
                className={styles.appleIcon}
              />
              <p>App Store</p>
            </motion.button>
            <motion.button
              className={`${styles.actionButton} ${styles.googleButton}`}
              whileHover={{ scale: 1.05 }}
            >
              <FontAwesomeIcon icon={faGooglePlay} size="2x" />
              <p>Play Store</p>
            </motion.button>
          </motion.div>
          <div className={inView ? styles.hidden : styles.scrollIndicator}>
            <FontAwesomeIcon icon={faChevronDown} size="2x" />
          </div>
          <motion.div
            className={styles.imageWrapper}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <img
              src={landing1}
              alt="Boba Landing"
              className={styles.landingImage}
            />
          </motion.div>
          <div className={styles.curve}></div>
        </section>

        <section className={styles.dark}>
          <motion.div
            ref={ref}
            className={styles.featureContainer}
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1, // Stagger the animations of children
                },
              },
            }}
          >
            {featureCards.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </motion.div>
        </section>
      </div>
      <section className={styles.purple}>
        <div className={styles.wave}>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className={styles.shapeFill}
            ></path>
          </svg>
        </div>
        <h1 className={styles.cardHeading}>Istraži i osvoji!</h1>
        <MobileShowcase />
        <div className={styles.mobileSwiper}>
          <HorizontalSwiper />
        </div>
      </section>
      <section className={styles.functionalitySection}>
        <div className={styles.scanSection}>
          <FeatureCard heading={scanHeading} text={scanText} width="60%" />
          <img
            src={receiptImage}
            alt="Skeniraj račun!"
            className={styles.receiptImage}
          />
        </div>
        <div className={styles.scanSection2}>
          <img
            src={qrScanImage}
            alt="Skeniraj račun!"
            className={styles.receiptImage}
          />
          <FeatureCard heading={qrHeading} text={qrText} width="60%" />
        </div>
      </section>
      {/* <section className={styles.bannerSection}>
        <img src={bannerImage} alt="Boba aplikacija!" className={styles.bannerImage} />
      </section> */}
      <section className={styles.contactSection} ref={sectionRef}>
        <div className={styles.headingContainer}>
          <div className={styles.cardHeading}>Imate pitanje?</div>
          <h2 className={styles.cardText}>Kontaktirajte nas</h2>
        </div>
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
