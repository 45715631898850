import React from "react";
import styles from "./Footer.module.css";
import logo from "../../assets/logo.svg";
import microsoft from "../../assets/MicrosoftStartup.png";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer: React.FC = () => {
  return (
    <div>
      <div className={styles.footer}>
        <div className={styles.logoWrapper}>
          <img src={logo} className={styles.logo} alt="Logo" />
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.contact}>
            <h2>Kontakt podaci</h2>
            <p>Adresa: Grbavička bb</p>
            <p>Bosna i Hercegovina, Sarajevo</p>
            <p>Tel: +387 (0)33 811 010</p>
          </div>
          <div className={styles.appInfo}>
            <h2>Zapratite nas</h2>
            <div className={styles.social}>
              <a href="https://www.instagram.com/boba_app">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://www.linkedin.com/company/boba-app">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </div>
        </div>
        <img src={microsoft} className={styles.microsoftImage} alt="Logo" />
      </div>
      <div className={styles.copyright}>
        <p>&copy; {new Date().getFullYear()} Sva prava zadržana</p>
      </div>
    </div>
  );
};

export default Footer;
