import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://bobaapp.azurewebsites.net/api/",
  headers: {
    // eslint-disable-next-line quote-props
    Accept: "text/plain",
    "Content-Type": "application/json",
  },
});

export default axiosClient;
