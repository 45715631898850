import styles from "./ApproveButton.module.css";

interface ButtonProps{
  onPress: () => void
}
const ApproveButton = (props: ButtonProps) => {

  const claimReward = () => {
    props.onPress()
  }
  return (
    <button className={`${styles.actionButton} ${styles.googleButton}`} onClick={claimReward}>
      <p>Odobri zahtjev</p>
    </button>
  );
};

export default ApproveButton;
