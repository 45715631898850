import { useInView } from "react-intersection-observer";
import styles from "./FeatureCard.module.css";
import { motion } from "framer-motion";

interface FeatureProps {
  heading: string;
  text: string;
  width?: string;
}

const FeatureCard = (props: FeatureProps) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <motion.div
      className={styles.cardContainer}
      ref={ref}
      style={props.width ? { width: props.width } : {}}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1.2 }}
    >
      <h1 className={styles.cardHeading}>{props.heading}</h1>
      <p className={styles.cardText}>{props.text}</p>
    </motion.div>
  );
};

export default FeatureCard;
