import styles from "./MobileShowcase.module.css";
import { motion } from "framer-motion";
import loopPhone from "../../assets/loop-phone-3.png";
import qrPhone from "../../assets/qr-phone-3.png";
import leaderboardPhone from "../../assets/leaderboard.png";
import searchPhone from "../../assets/search.png";
import singlePhone from "../../assets/single.png";
import { useInView } from "react-intersection-observer";

const MobileShowcase = () => {
  const { ref, inView } = useInView({ threshold: 0 });

  const animateImage = (delay: number) => ({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : -10,
    transition: { duration: 0.5, delay: inView ? delay : 0, ease: "easeIn" },
  });

  return (
    <motion.div className={styles.mobileShowcase} ref={ref}>
      <motion.img
        src={searchPhone}
        alt="Find your new favorite places!"
        className={`${styles.thirdRowShowcase} ${styles.singleShowcase}`}
        whileHover={{ y: -10, transition: { duration: 0.1 } }}
        initial={{ opacity: 0 }}
        animate={animateImage(0)}
      />
      <motion.img
        src={qrPhone}
        alt="Get Scanned and earn Rewards!"
        className={`${styles.secondRowShowcase} ${styles.singleShowcase}`}
        whileHover={{ y: -10, transition: { duration: 0.1 } }}
        initial={{ opacity: 0 }}
        animate={animateImage(0.3)}
      />
      <motion.img
        src={loopPhone}
        alt="Stay in the Loop!"
        className={`${styles.centerShowcase} ${styles.singleShowcase}`}
        whileHover={{ y: -10, transition: { duration: 0.1 } }}
        initial={{ opacity: 0 }}
        animate={animateImage(0.5)}
      />
      <motion.img
        src={leaderboardPhone}
        alt="Achieve the first place in your favorite spot!"
        className={`${styles.secondRowShowcase} ${styles.singleShowcase}`}
        whileHover={{ y: -10, transition: { duration: 0.1 } }}
        initial={{ opacity: 0 }}
        animate={animateImage(0.3)}
      />
      <motion.img
        src={singlePhone}
        alt="Find cool rewards!"
        className={`${styles.thirdRowShowcase} ${styles.singleShowcase}`}
        whileHover={{ y: -10, transition: { duration: 0.1 } }}
        initial={{ opacity: 0 }}
        animate={animateImage(0)}
      />
    </motion.div>
  );
};

export default MobileShowcase;
