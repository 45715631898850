import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

import loopPhone from "../../assets/loop-phone-3.png";
import qrPhone from "../../assets/qr-phone-3.png";
import leaderboardPhone from "../../assets/leaderboard.png";
import searchPhone from "../../assets/search.png";
import singlePhone from "../../assets/single.png";

import styles from "./HorizontalSwiper.module.css";

const HorizontalSwiper = () => {
  return (
    <Swiper
      loop={true}
      className={styles.mySwiper}
      slidesPerView="auto"
      init={true}
      pagination={true}
      modules={[Pagination]}
    >
      <SwiperSlide className={styles.swiperSlide}>
        <img src={loopPhone} alt="Loop" className={styles.mobileView} />
      </SwiperSlide>
      <SwiperSlide className={styles.swiperSlide}>
        <img src={qrPhone} alt="Loop" className={styles.mobileView} />
      </SwiperSlide>
      <SwiperSlide className={styles.swiperSlide}>
        <img src={searchPhone} alt="Loop" className={styles.mobileView} />
      </SwiperSlide>
      <SwiperSlide className={styles.swiperSlide}>
        <img src={singlePhone} alt="Loop" className={styles.mobileView} />
      </SwiperSlide>
      <SwiperSlide className={styles.swiperSlide}>
        <img src={leaderboardPhone} alt="Loop" className={styles.mobileView} />
      </SwiperSlide>
    </Swiper>
    // <div className={styles.swiper}>
    //   <div className="swiper-wrapper">
    //     <img src={loopPhone} alt="Loop" className={styles.mobileView} />
    //     <img src={qrPhone} alt="Loop" className={styles.mobileView} />
    //     <img src={searchPhone} alt="Loop" className={styles.mobileView} />
    //     <img src={singlePhone} alt="Loop" className={styles.mobileView} />
    //     <img
    //       src={leaderboardPhone}
    //       alt="Loop"
    //       className={styles.centerShowcase}
    //     />
    //   </div>
    // </div>
  );
};

export default HorizontalSwiper;
