import { useEffect, useState } from "react";
import styles from "./RewardData.module.css";
import { DraftReward } from "../../models/Reward/Reward";

interface RewardDataProps {
  rewardData: DraftReward[];
}

const RewardData = (props: RewardDataProps) => {
  const [rewardData, setRewardData] = useState<DraftReward[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [bobasAmount, setBobasAmount] = useState<number>(0)

  useEffect(() =>{
    setRewardData(props.rewardData)
    calculateValues(props.rewardData)
  }, [props])


  const calculateValues = (rewardData: DraftReward[]): void => {
      let totalPrice = 0;
      let totalRewardPoints = 0;
    
      rewardData.forEach((item) => {
        // Calculate total price for the item
        const itemPrice = item.price * item.amount;
        totalPrice += itemPrice;
    
        // Calculate total reward points for the item
        const itemRewardPoints = item.rewardPoints * item.amount;
        totalRewardPoints += itemRewardPoints;
      });
    
      setTotalPrice(totalPrice)
      setBobasAmount(totalRewardPoints)
  }

  return (
    <div className={styles.itemsContainer}>
      {rewardData.map((item: any) => (
        <div key={"reward-" + item.rewardName} className={styles.body14}>
          {item.amount}x {item.rewardName}
        </div>
      ))}
      <div className={styles.body14}>{totalPrice} KM</div>
      <div className={styles.bobaContainer}>
        <div className={styles.heading11}>Redeem for {bobasAmount}</div>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.68327 2.3L5.49987 1L1.2 3.6V8.46667L5.49987 11L9.86667 8.46667V6.03333"
            stroke="#5A67DF"
            stroke-width="1.2"
            stroke-linecap="round"
          />
          <path
            d="M10.8584 2.38983L5.49574 7.00413L4.11295 5.39544"
            stroke="#5A67DF"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default RewardData;
