import { useState } from "react";
import styles from "./FormInput.module.css";

interface InputProps {
  label: string;
  type?: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiline?: boolean;
}

const FormInput = (props: InputProps) => {
  const [inputValue, setInputValue] = useState(props.value);

  const handleInputChange = (event: any) => {
    props.onChange(event);
    setInputValue(event.target.value);
  };

  const inputElement = props.multiline ? (
    <textarea
      name={props.name}
      autoComplete="off"
      value={props.value}
      className={`${styles.input} ${styles.textareaResizable} ${
        inputValue.trim() !== "" ? styles.inputHasContent : ""
      }`}
      onChange={handleInputChange}
    />
  ) : (
    <input
      type={props.type || "text"} // Default type to 'text' if not specified
      name={props.name}
      autoComplete="off"
      value={props.value}
      className={`${styles.input} ${
        inputValue.trim() !== "" ? styles.inputHasContent : ""
      }`}
      onChange={handleInputChange}
    />
  );

  return (
    <div className={styles.inputGroup}>
      {inputElement}
      <label className={styles.userLabel}>{props.label}</label>
    </div>
  );
};

export default FormInput;
