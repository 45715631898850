import styles from "./ProfileSection.module.css";
import profileImage from "../../assets/default-image.png"
import { useEffect, useState } from "react";
import { User } from "../../models/User/User";
import { getSingleUserBobasByPlace} from "../../lib/user";

interface ProfileSectionProps{
  user: User
  placeId: number
}

const ProfileSection = (props: ProfileSectionProps) => {
  const [user, setUser] = useState<User>()
  const [userBoba, setUserBoba] = useState<number>(0)

  useEffect(() =>{
    if(props.user !== undefined){
      setUser(props.user)
    }
  }, [props.user])

  useEffect(() => {
    if(user !== undefined){
      fetchUserBobas(props.placeId, user.id)
    }
  }, [user, props.placeId])

  const fetchUserBobas = async (placeId: number, userId: number): Promise<void> => {
    try{
      const response = await getSingleUserBobasByPlace(placeId, userId)
      if(response.success){
        setUserBoba(response.data.userBoba)
      }
    } catch(error: any){
      console.error('Error fetching user bobas ', error)
    }
  }

  return (
    <div className={styles.userContainer}>
      <img src={profileImage} alt="Profile" className={styles.profileImage} />
      <div className={styles.userInfoContainer}>
        {user !== undefined && <div className={styles.heading14}>{user.firstName} {user.lastName}</div>}
        <div className={styles.bobasContainer}>
          <div className={styles.heading14}>{userBoba}</div>
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.649994 8.46667V3.6L4.94987 1L9.31666 3.6V8.46667L4.94987 11L0.649994 8.46667Z"
              stroke="#FF6525"
              stroke-width="1.2"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
