import { useRef } from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";

function App() {
  const contactSectionRef = useRef<HTMLElement>(null);

  const scrollToContactSection = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Navbar scrollToContact={scrollToContactSection} />
      <Home sectionRef={contactSectionRef} />
      <Footer />
    </div>
  );
}

export default App;
