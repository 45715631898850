import styles from './Snackbar.module.css'

interface SnackbarProps {
    text: string;
    success: boolean | null;
}

const Snackbar = (props: SnackbarProps) => {
    return (
        <div className={`${styles.snackbarContainer} ${props.success === true ? styles.backgroundSuccess : styles.backgroundFailure}`}>
            <div className={styles.snackbarText}>{props.text}</div>
            <div className={styles.closeIcon}>&times;</div>
        </div>
    );
};
export default Snackbar;